<template>
  <div class="series-collection-details-container">
    <!-- 展示大图 -->
    <goods-item
      :videoIsPlay="videoIsPlay"
      :goodsType="managementListInfo.type"
      :goodsSrc="managementListInfo.img"
      :goodsPoster="managementListInfo.coverImg"
      :goodsSource="managementListInfo.detailImg"
    ></goods-item>
    <!-- 馆藏简介 -->
    <div class="management-info">
      <div class="management-info-time row-center">
        {{ lowTime }}
      </div>
      <p class="management-info-title">{{ managementInfo.title }}</p>
      <div class="management-info-creatorAvatar-creatorName row-start">
        <img :src="managementInfo.creatorAvatar" />
        <p>{{ managementInfo.creatorName }}</p>
      </div>
      <p class="management-info-content">
        {{ managementInfoContent }}
        <a
          @click="showMore"
          v-if="managementInfo.content.length > 80 && managementInfoContent.length <= 83"
        >
          展开
          <img />
          <i
            style="font-weight: bold;"
            class="el-icon-arrow-down"
          ></i>
        </a>
        <a
          @click="hideMore"
          v-if="managementInfoContent.length > 83"
        >
          收起
          <img />
          <i
            style="font-weight: bold;"
            class="el-icon-arrow-up"
          ></i>
        </a>
      </p>
    </div>
    <!-- 抽签购 -->
    <lottery
      v-if="seriesCollectionInfo.drawFlag == 1 && seriesCollectionInfo.drawlotsConfig"
      :lotteryInfo="lotteryInfo"
      @drawlotsApply="drawlotsApplyNow"
    />
    <!-- 馆藏商品 -->
    <p class="series-collection-all-size">本系列共{{ allProductList.length }}个藏品</p>
    <van-list
      v-if="!noList"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
    >
      <div class="collection-list">
        <collection-item
          v-for="item in collectionList"
          :key="item.id"
          :collectionInfo="item"
        >
        </collection-item>
      </div>

    </van-list>
    <div
      v-if="noList"
      class="col-center"
      style="width:100%;margin-top:10vh;"
    >
      <img
        style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
        src="../assets/icon/no-list.png"
      />
      <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;margin-bottom: 10vh;">暂无数据</p>
    </div>
    <!-- 提示弹窗 -->
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-tip-title"
      >{{ goodBuyTitle }}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >{{ goodBuyTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import GoodsItem from '../components/GoodsItem.vue'
import CollectionItem from '../components/CollectionItem.vue'

import Lottery from '../components/Lottery.vue'
import { Swipe,SwipeItem,List,Popup } from 'vant';
import { Menu,MenuItem } from 'element-ui';
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(List);
Vue.use(Popup);

export default {
  components: {
    GoodsItem,CollectionItem,Lottery
  },
  data () {
    return {
      lowTime: '',
      browerOpenVisible: false,
      goodBuyTitle: '',
      goodBuyTip: '',
      videoIsPlay: true,
      loading: false,
      finished: false,
      noList: false,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      seriesCollectionInfo: {},
      managementListInfo: {},
      collectionList: [
      ],
      allProductList: [],
      openSoonProductList: [],
      soldProductList: [],
      managementInfo: {
        title: '',
        creatorAvatar: '',
        creatorName: '',
        content: ''
      },
      lotteryInfo: {
        status: '1', // 1未开始 2抽签中 3已报名 4报名截止 5已抽签未中 6已抽签已中
        id: '',
        limitInfo: '1000',
        drawLotsStartTime: '10.1 12:00',
        drawLotsEndTime: '10.5 12:00',
        drawLotsTime: '10.5 12:00',
        sellingTime: '10.5 12:00',
        rule: `<p>规则</p>`
      },
      managementInfoContent: '',
    }
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
  },
  methods: {
    // 下拉加载
    load () {
      this.loading = true
      this.goodsParam.page += 1
      if (this.allProductList.length == 0) {
        this.getManagementInfo()
      } else {
        this.selectManagementInfo()
      }
    },
    // 根据tab切换改变内容
    selectManagementInfo () {
      this.noList = this.allProductList.length == 0 ? true : false
      this.allProductList.map((i,j) => {
        this.collectionList.push({
          id: i.id,
          image: i.image,
          name: i.storeName,
          exclusiveBuyType: i.exclusiveBuyType,
          limitInfo: i.limitInfo,
          price: i.price,
          cateId: i.cateId,
          isOpen: i.isOpen,
          stock: i.stock,
          sellStatus: i.sellStatus,
          startTime: i.startTime,
          endTime: i.endTime
        })
      })
      this.finished = true
    },
    // 抽签报名
    drawlotsApplyNow () {
      api
        .post('drawlots/apply',{ id: this.seriesCollectionInfo.drawlotsConfig.id })
        .then(result => {
          if (result.data.success) {
            this.goodBuyTitle = '报名成功！'
            this.goodBuyTip = '请耐心等待抽签'
            this.browerOpenVisible = true
            this.getLotteryInfo(1,this.seriesCollectionInfo.drawlotsConfig.id)
          } else {
            this.getLotteryInfo(1,this.seriesCollectionInfo.drawlotsConfig.id)
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取报名抽签结果
    getLotteryInfo (type,id) {
      api
        .get('drawlots/getStatus?type=' + type + '&id=' + id)
        .then(result => {
          if (result.data.success) {
            if (type == 1) {
              this.lotteryInfo.status = result.data.data.status ? 3 : 2
            } else {
              this.lotteryInfo.status = result.data.data.status ? 6 : 5
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    clearZreo (val) {
      return val.substring(0,1) == 0 ? val.substring(1,) : val
    },
    // 获取管藏详情
    getManagementInfo () {

      api
        .get('collect/series/detail/' + this.$route.query.id)
        .then(result => {
          if (result.data.success) {
            this.seriesCollectionInfo = result.data.data
            this.lowTime = this.clearZreo(this.seriesCollectionInfo.startTime.substring(5,7)) + '月' + this.clearZreo(this.seriesCollectionInfo.startTime.substring(8,10)) + '日 开售'
            this.managementInfo.title = this.seriesCollectionInfo.name
            this.managementInfo.content = this.seriesCollectionInfo.brief
            this.managementInfo.creatorAvatar = this.seriesCollectionInfo.creatorAvatar
            this.managementInfo.creatorName = this.seriesCollectionInfo.creatorName
            this.managementInfoContent = this.managementInfo.content.length > 80 ? this.managementInfo.content.substring(0,80) + '...' : this.managementInfo.content

            // 抽签购组件数据处理
            if (this.seriesCollectionInfo.drawFlag == 1) {
              if ((new Date()).getTime() < this.seriesCollectionInfo.drawlotsConfig.drawLotsStartTime) {
                this.lotteryInfo.status = 1
              } else if ((new Date()).getTime() < this.seriesCollectionInfo.drawlotsConfig.drawLotsEndTime) {
                this.getLotteryInfo(1,this.seriesCollectionInfo.drawlotsConfig.id)

              } else if ((new Date()).getTime() < this.seriesCollectionInfo.drawlotsConfig.drawLotsTime) {
                this.lotteryInfo.status = 4
              } else {
                if (this.seriesCollectionInfo.drawlotsConfig.status == 0) {
                  this.lotteryInfo.status = 4
                } else {
                  this.getLotteryInfo(2,this.seriesCollectionInfo.drawlotsConfig.id)
                }
              }
              this.lotteryInfo.drawLotsStartTime = this.$moment(this.seriesCollectionInfo.drawlotsConfig.drawLotsStartTime).format("MM.DD HH:mm")
              this.lotteryInfo.drawLotsEndTime = this.$moment(this.seriesCollectionInfo.drawlotsConfig.drawLotsEndTime).format("MM.DD HH:mm")
              this.lotteryInfo.drawLotsTime = this.$moment(this.seriesCollectionInfo.drawlotsConfig.drawLotsTime).format("MM.DD HH:mm")
              this.lotteryInfo.sellingTime = this.$moment(this.seriesCollectionInfo.drawlotsConfig.sellingTime).format("MM.DD HH:mm")
              this.lotteryInfo.rule = this.seriesCollectionInfo.drawlotsConfig.drawLotsRuls
              this.lotteryInfo.id = this.seriesCollectionInfo.drawlotsConfig.id
              this.lotteryInfo.limitInfo = String(this.seriesCollectionInfo.drawlotsConfig.drawLotsNum) + '份'
            }

            this.managementListInfo = {
              type: 'img',
              img: result.data.data.image
            }
            // 全部商品
            this.allProductList = result.data.data.productVoList
            this.selectManagementInfo()

          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    getManagementGoodInfo () {
      // 下拉加载数据逻辑
    },
    showMore () {
      this.managementInfoContent = this.managementInfo.content
    },
    hideMore () {
      this.managementInfoContent = this.managementInfo.content.substring(0,80) + '...'
    },
    onChange () {
      this.videoIsPlay = this.videoIsPlay ? false : true
    },
  },
}
</script>
<style lang="less" scoped>
.series-collection-details-container {
  width: 100vw;
  height: auto;
  background: #1c1c1c;
  /deep/.van-swipe__indicators {
    bottom: 30px;
    left: auto;
    right: 3%;
  }
  /deep/.van-swipe__indicator {
    width: 5px;
    height: 5px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 100%;
    opacity: 1;
    -webkit-transition: opacity 1s, background-color 0.2s;
    transition: opacity 1s, background-color 0.2s;
  }
  .management-info {
    width: 94vw;
    padding: 17px 0;
    height: auto;
    background: rgba(42, 42, 42, 0.89);
    backdrop-filter: blur(8px);
    min-height: 36px;
    margin-left: 3%;
    margin-top: -20px;
    position: relative;
    .management-info-time {
      width: 120px;
      height: 32px;
      background: rgba(255, 255, 255, 0.53);
      backdrop-filter: blur(8px);
      position: absolute;
      left: 0;
      top: -32px;
      font-size: 15px;
      font-family: lantingheiweight;
      font-weight: 400;
      color: #444444;
      line-height: 20px;
    }
    .management-info-title {
      width: 90%;
      margin-left: 5%;
      text-align: left;
      font-size: 22px;
      font-family: lantingheiweight;
      font-weight: bold;
      color: #ffffff;
      line-height: 31px;
      letter-spacing: 1px;
    }
    .management-info-content {
      width: 90%;
      margin-left: 5%;
      font-size: 14px;
      font-family: lantingheiweight;
      font-weight: 700;
      color: #ffffff;
      line-height: 24px;
      margin-top: 10px;
      letter-spacing: 1px;
    }
  }
  .order-menu {
    width: 94vw;
    margin-left: 3vw;
    position: inherit;
    background: transparent !important;
    .el-menu {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: transparent !important;
      background-color: transparent !important;
    }
    .el-menu-item {
      font-size: 14px;
    }
    .el-menu-item1 {
      margin-right: 20px;
    }
  }
  .collection-list {
    width: 94vw;
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }
  .series-collection-all-size {
    width: 94vw;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
    letter-spacing: 1px;
    margin: 17px 3% 12px 3%;
  }
  .management-info-creatorAvatar-creatorName {
    margin-top: 10px;
    img {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      margin: 0 9px 0 23px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 17px;
    }
  }
}
</style>