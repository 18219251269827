<template>
  <div class="good-lottery-container">
    <div
      class="lottery-apply"
      :style="(lotteryInfo.status == '5' || lotteryInfo.status == '6') && Cookies.get('Login') ? 'height: 150px;' : 'height: 120px;'"
    >
      <div class="lottery-rule-limit row-between">
        <div class="lottery-rule-limit-left">
          <div class="lottery-tip">限时</div>
          <div class="lottery-title">抽签申购</div>
          <limit-item
            style="margin-left: 12px;"
            v-if="lotteryInfo.limitInfo"
            limitTitle="名额"
            :limitContent="lotteryInfo.limitInfo"
          ></limit-item>
        </div>
        <div class="lottery-rule-limit-right">
          <p
            @click="ruleDetails"
            class="lottery-rule"
          >规则</p>
          <!-- 1未开始 2抽签中 3已报名 4报名截止 5已抽签 -->
          <div
            class="row-center"
            @click="getLotteryList()"
            :class="lotteryInfo.status == '5' || lotteryInfo.status == '6'  ? 'lottery-begin' : 'lottery-not-start'"
          >{{ lotteryInfo.status == 1 ? '未开始' : lotteryInfo.status == 2 ? '立即报名' : lotteryInfo.status == 3 ? '已报名' : lotteryInfo.status == 4 ? '报名结束' : '中签名单' }}</div>
        </div>
      </div>
      <p
        :class="lotteryInfo.status == '5' ? 'good-not-lottery' : 'good-lottery'"
        v-if="(lotteryInfo.status == '5' || lotteryInfo.status == '6') && Cookies.get('Login')"
      >{{ lotteryInfo.status == '6' ? '恭喜您，您中签啦！' : '很遗憾，您未中签'}}</p>
    </div>
    <div class="lottery-time">
      <p class="lottery-time-title">时间节点</p>

      <div style="position: relative;">
        <img
          class="lottery-time-line"
          src="../assets/icon/time-line.png"
        />
        <p class="lottery-time-line-tip tip1">报名时间</p>
        <p class="lottery-time-line-tip tip2">抽签</p>
        <p class="lottery-time-line-tip tip3">开售</p>

        <p class="lottery-time-line-tip time1">{{ lotteryInfo.drawLotsStartTime}}</p>
        <p class="lottery-time-line-tip time2">{{ lotteryInfo.drawLotsEndTime}}</p>
        <p class="lottery-time-line-tip time3">{{ lotteryInfo.drawLotsTime}}</p>
        <p class="lottery-time-line-tip time4">{{ lotteryInfo.sellingTime}}</p>
      </div>

    </div>
    <!-- 立即购买 -->
    <van-popup
      v-model="isShowRule"
      closeable
      position="bottom"
      style="height: 60vh;background: #262626;position: fixed;bottom: 0px !important;left: 0;"
    >
      <p class="lottery-rule-content">规则</p>
      <div
        class="lottery-introduce"
        v-html="lotteryInfo.rule"
      >
      </div>
    </van-popup>
  </div>
</template>
<script>
import LimitItem from './LimitItem.vue'
import Cookies from 'js-cookie'

export default {
  props: {
    lotteryInfo: {
      type: Object,
      default: {}
    },
  },
  components: {
    LimitItem
  },
  data () {
    return {
      isShowRule: false,
      Cookies,
    }
  },
  methods: {
    ruleDetails () {
      this.isShowRule = true
    },
    getLotteryList () {
      if (this.lotteryInfo.status == 2) {
        this.$emit('drawlotsApply')
      }
      if (this.lotteryInfo.status == 5 || this.lotteryInfo.status == 6) {
        this.$router.push('/lotteryList?id=' + this.lotteryInfo.id)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.lottery-apply {
  margin-top: 0.75rem;
  width: 90%;
  margin-left: 5%;
  background: #2c2c2c;
  border-radius: 8px;
  .lottery-rule-limit {
    .lottery-rule-limit-left,
    lottery-rule-limit-right {
      margin: 14px 0 21px 0;
    }
    .lottery-tip {
      height: 1.25rem;
      width: 2.5rem;
      background: url('../assets/img/store/store_no.png') no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1c1c1c;
      font-size: 0.75rem;
    }
    .lottery-title {
      margin: 12px 0 11px 12px;
      font-size: 16px;
      font-family: lantingheiweight;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      letter-spacing: 1px;
    }
    .lottery-rule {
      font-size: 12px;
      font-weight: 500;
      color: #f3cdb6;
      line-height: 17px;
      margin-right: 1.1875rem;
      margin-bottom: 1.75rem;
      text-align: right;
    }
    .lottery-not-start {
      width: 100px;
      height: 38px;
      position: relative;
      right: 0;
      background-image: url('../assets/icon/open-soon.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-color: transparent;
      margin: 1.3125rem 1.3125rem 0 1.1875rem;
      font-size: 1rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      cursor: pointer;
    }
    .lottery-begin {
      width: 100px;
      height: 38px;
      position: relative;
      right: 0;
      margin: 0 1.3125rem 0 1.1875rem;
      background-image: url('../assets/icon/confirm-button.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-color: transparent;
      font-size: 1rem;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      letter-spacing: 2px;
      cursor: pointer;
    }
  }
}
.lottery-time {
  margin-top: 0.625rem;
  margin-left: 5%;
  width: 90%;
  height: 120px;
  background: #2c2c2c;
  border-radius: 8px;
  .lottery-time-title {
    margin: 0px 0 45px 5%;
    padding-top: 12px;
    font-size: 16px;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    letter-spacing: 1px;
    webkit-text-size-adjust: none;
  }
  .lottery-time-line-tip {
    font-size: 12px;
    font-family: lantingheiweight;
    font-weight: 500;
    color: #f3cdb6;
    line-height: 16px;
    webkit-text-size-adjust: none;
  }
  .tip1 {
    position: absolute;
    top: -14px;
    left: 18%;
  }
  .tip2 {
    position: absolute;
    top: -14px;
    right: 30%;
  }
  .tip3 {
    position: absolute;
    top: -14px;
    right: 4%;
  }
  .time1 {
    position: absolute;
    bottom: -14px;
    left: 4%;
  }
  .time2 {
    position: absolute;
    bottom: -14px;
    left: 32%;
  }
  .time3 {
    position: absolute;
    bottom: -14px;
    right: 25%;
  }
  .time4 {
    position: absolute;
    bottom: -14px;
    right: 4%;
  }
  .lottery-time-line {
    margin: 0 5%;
    width: 90%;
  }
}
.lottery-rule-content {
  margin-top: 32px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
.lottery-introduce {
  width: 90%;
  margin-left: 5%;
  height: 50vh;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  font-size: 14px;
  font-family: NotoSansHans-Regular, NotoSansHans;
  font-weight: 400;
  color: #cccccc;
  line-height: 24px;
  word-break: break-all;
}
.good-lottery {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-family: lantingheiweight;
  font-weight: 600;
  color: #f3cdb6;
  line-height: 18px;
}
.good-not-lottery {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-family: lantingheiweight;
  font-weight: 600;
  color: #999999;
  line-height: 18px;
}
</style>